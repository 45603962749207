// -----------------------------------------------------------------------------
// Styles for all button components.
// -----------------------------------------------------------------------------

.btn{
	border-radius: 0;
	border: none;
	padding: 10px 20px;
}

.error404 .btn-default {
    display: block;
    width: 280px;
    margin-top: 30px;
}

.btn-default,
.btn-success {
    color: #fff;
    background-color: $vert;
    transition: $transition;
	&:hover, &:focus{
        color: #fff;
		background-color: $bleu;
	}
}