// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
	background-color: white;
    margin-top: 2em;
    padding: 3em 2em;

    // border-top: 1px solid #D3D3D3;

    text-align: center;

    h3{
    	margin-top: 0;
    }
}