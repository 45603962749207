// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------

// input
// textarea
// button
// select
// option
// optiongroup
// fieldset
// label

.contact__form__input-holder {
    margin-bottom: 35px;
}

.contact__form__input-holder br {
    display: none;
}

label {
    display: block;
    // text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
}

.contact__form__input {
    padding: 12px 10px;
    width: 100%;
    border: none;
    border-bottom: 3px solid #ccc;
    background-color: #f8f8f8;
    transition: $transition;
}

.contact__form__input:focus {
    outline: none;
    border: none;
    border-bottom: 3px solid $vert;
    background-color: rgba(0, 159, 127, .1);
}

textarea.contact__form__input {
    resize: none;
}

.contact__form__input-holder button {
    position: relative;
    padding: 30px;
    width: 100%;
    font-size: 1.25em;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    background-color: $bleu;
    border: none;
    transition: $transition;
}

.contact__form__input-holder button:hover,
.contact__form__input-holder button:focus {
    background-color: $vert;
}

// CF7 Styles
.wpcf7-form {
    margin-top: 25px;
}

div.wpcf7-response-output {
    padding: 12px;
    text-align: center;
}

// OK
div.wpcf7-mail-sent-ok {
    border-color: $vert;
    color: $vert;
}

// Error
div.wpcf7-validation-errors {
    border-color: #ce0325;
    color: #ce0325;
}

span.wpcf7-not-valid-tip {
    position: relative;
    margin-top: 5px;
    font-size: 12px;
    color: #ce0325;
}

span.wpcf7-not-valid-tip:before {
    display: inline-block;
    content: '\f071';
    font-family: 'FontAwesome';
    margin-right: 5px;
}