// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.container .jumbotron{
	text-align: left;
	background-color: white;
	border-radius: 0;
	color: $bleu;
	// border: 5px solid white;
	margin-bottom: 0;

	@include respond-to('sm') {
		transform: translateY(-30px);
	}

	.promesses{
		margin-top: 50px;
		text-align: center;
		.picto{
			font-size: 3em;
			display: block;
			background-color: $vert;
			width: 100px;
			height: 100px;
			margin: 0 auto;
			border-radius: 50%;
			color: white;
			margin-bottom: 20px;
			i{
				line-height: 100px;
			}
		}
		h4{
			font-family: $text-font-stack;
			color: $grismoyen;
			// text-transform: uppercase;
			font-weight: bold;
			small{
				margin-top: 10px;
				margin-bottom: 50px;
			}
		}
	}
}