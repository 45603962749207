// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.mt20{
	margin-top: 20px;
}
.mt30{
	margin-top: 30px;
}
.mt50{
	margin-top: 50px;
}
.mt100{
	margin-top: 100px;
}
.mb20{
	margin-bottom: 20px;
}
.mb30{
	margin-bottom: 30px;
}
.mb50{
	margin-bottom: 50px;
}
.mb100{
	margin-bottom: 100px;
}

.stack{
	font-family: $text-font-stack;
}

.bleu{
	color: $bleu;
}

.vert{
	color: $vert;
}

.bg-bleu{
	background-color: $bleu;
	padding: 20px;
	color: white;
}

.bg-vert{
	background-color: $vert;
	padding: 20px;
	color: white;
}