// -----------------------------------------------------------------------------
// Basic typography style for copy text
// -----------------------------------------------------------------------------
//
// see: http://www.w3schools.com/html/default.asp

/*------------------------------------*\
  #HEADINGS
\*------------------------------------*/

// Defines HTML headings
h1 {
    font-family: $text-font-headings;
    // ..
}

h2 {
    font-family: $text-font-headings;
    font-weight: bold;
    font-size: 20px;
    @include respond-to('md') {
        font-size: 30px;
    }
}

h3 {
    font-family: $text-font-headings;
    font-weight: bold;
    // margin-top: 20px;
    // margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

h4 {
    font-family: $text-font-headings;
    font-weight: bold;
    // margin-top: 50px;
    // ..
}

h5 {
    font-family: $text-font-headings;
    // ..
}

h6 {
    font-family: $text-font-headings;
    // ..
}


/*------------------------------------*\
  #PARAGRAPHS
\*------------------------------------*/

// Defines a paragraph
p {
    // ..
    &.lead{
        padding: 20px 0px;
        font-size: 14px;
        @include respond-to('md') {
            font-size: 21px;
        }
    }
}


/*------------------------------------*\
  #FORMATTING
\*------------------------------------*/

// Defines bold text
b {
    // ..
}

// Defines emphasized text
em {
    // ..
}

// Defines italic text
i {
    // ..
}

// Defines smaller text
small {
    display: block;
}

// Defines important text
strong {
    // ..
}

// Defines subscripted text
sub {
    // ..
}

// Defines superscripted text
sup {
    // ..
}

// Defines inserted text
ins {
    // ..
}

// Defines deleted text
del {
    // ..
}

// Defines marked/highlighted text
mark {
    // ..
}


/*------------------------------------*\
  #LINKS
\*------------------------------------*/

// Defines a hyperlink
a {
    // ..
}


/*------------------------------------*\
  #LISTS
\*------------------------------------*/

// Defines an unordered list
ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 20px;

    li {
        margin-top: 25px;
        position: relative;
        &:before{
            position: absolute;
            content: "\f00c";
            font-family: FontAwesome;
            left: -20px;
        }
    }
}

// Defines an ordered list
ol {
    // ..

    li {
        // ..
    }
}

// Defines a description list
dl {
    // ..

    dt {
        // ..
    }

    dd {
        // ..
    }
}


/*------------------------------------*\
  #QUOTATION AND CITATION ELEMENTS
\*------------------------------------*/

// Defines an abbreviation or acronym
abbr {
    // ..
}

// Defines contact information for the author/owner of a document
address {
    // ..
}

// Defines a section that is quoted from another source
blockquote {
    // ..
}

// Defines the title of a work
cite {
    // ..
}