// -----------------------------------------------------------------------------
// Basic project styling
// -----------------------------------------------------------------------------

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    position: relative;

    font-style: normal;
    font-family: $text-font-stack;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    
    color: $gris;
    background-color: white;
    
    @include respond-to("sm") {
        background-color: $grisclair;
    }
}