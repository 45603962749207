// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header {
	// padding-top: 20px;
	// background-color: rgba($bleu, 1);

	.container-fluid{
		position: relative;
		background-color: #def1f9;
		.maison{
			height: 400px;
			background: url("../images/gbe-etancheite-animation-maison.gif") no-repeat center;
			background-size: cover;
		}
		.logo{
			height: 220px;
			padding-top: 20px;
			text-align: center;

			> a:hover,
			> a:focus {
				text-decoration: none;
			}

			img{
				height: 120px;
			}
			.catchphrase{
				text-transform: uppercase;
				font-weight: bold;
				font-size: 12px;
				small{
					display: block;
					padding-top: 10px;
					font-weight: bold;
					letter-spacing: 1px;
				}
			}
		}
		@include respond-to('xs') {
			.maison{
				height: 600px;
			}
		}
		@include respond-to('sm') {
			.logo{
				background: none;
				padding-top: 100px;
				height: 400px;
				img{
					height: auto;
				}
				.catchphrase{
					font-size: 18px;
				}
			}
		}
		.barre-verte{
			content:"";
			position: absolute;
			display: none;
			height: 179px;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: $vert;
		}
		@include respond-to('sm') {
			.barre-verte{
				display: block;
			}
		}
		@include respond-to('md') {
			.logo{
				background: url("../images/gbe-etancheite-animation-fond.png") no-repeat center;
				background-size: cover;
				height: 600px;
			}
			.barre-verte{
				display: block;
			}
		}
	}
}