// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', sans-serif !default;
$text-font-headings: 'Dosis', sans-serif !default;


/// Copy text color
/// @type Color
$text-color: #222 !default;

/// Shades of grey
$grisfonce: #333;
$gris: #666;
$grismoyen: #999;
$grisclair: #E7E7E7;
$gristresclair: #F5F5F5;

/// Client colors
$bleu: #20a2b9;
$bleuciel: #def1f9;
$vert: #009f7f;

$transition: 0.3s ease-in-out;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
	'xs': (min-width: 480px),
	'sm': (min-width: 768px),
    'md': (min-width: 992px),
    'lg': (min-width: 1200px)
    ) !default;