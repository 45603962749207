// -----------------------------------------------------------------------------
// This file contains all styles related to the main of the site/application.
// -----------------------------------------------------------------------------

.img-responsive.centered{
	margin: 0 auto;
}

.image-container{
	background-color: white;
	width: 100%;
}

.container{
	text-align: center;
	@include respond-to('md') {
		text-align: left;
	}
}